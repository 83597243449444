import axios from 'axios';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { IUser } from '@/shared/model/user.model';

export default class UserManagementService {
  public get(userId: number): Promise<any> {
    return axios.get(`api/admin/users/${userId}`);
  }

  public create(user: IUser): Promise<any> {
    return axios.post('api/admin/users', user);
  }

  public update(user: IUser): Promise<any> {
    return axios.put('api/admin/users', user);
  }

  public remove(userId: number): Promise<any> {
    return axios.delete(`api/admin/users/${userId}`);
  }

  public retrieve(paginationQuery?: any, userFilter?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`api/admin/users/search?${buildPaginationQueryOpts(paginationQuery)}`, userFilter)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveAuthorities(): Promise<any> {
    return axios.get('api/authorities');
  }
}
