import { Component, Inject, Vue } from 'vue-property-decorator';
import OrganisationService from '@/entities/organisation/organisation.service';

@Component
export default class AccesMenu extends Vue {
  @Inject('organisationService') private organisationService: () => OrganisationService;

  private isAllowedToPerson: boolean = false;
  private isAllowedToVehicule: boolean = false;

  mounted() {
    this.canAccessToPerson();
    this.canAccessToVehicule();
  }

  async canAccessToPerson(): Promise<void> {
    const { account } = this.$store.getters;
    const isAdmin = account.authorities.includes('ROLE_ADMIN');

    if (isAdmin) {
      this.isAllowedToPerson = true;
    } else {
      const res = await this.organisationService().findAllowedPerson();
      this.isAllowedToPerson = res;
    }
  }

  async canAccessToVehicule(): Promise<void> {
    const { account } = this.$store.getters;
    const isAdmin = account.authorities.includes('ROLE_ADMIN');

    if (isAdmin) {
      this.isAllowedToVehicule = true;
    } else {
      const res = await this.organisationService().findAllowedVehicule();
      this.isAllowedToVehicule = res;
    }
  }
}
