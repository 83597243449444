import Vue from 'vue';

Vue.directive('format-label', {
  bind(el) {
    const label = el.getAttribute('label');
    if (typeof label !== 'undefined') {
      const regex = new RegExp('.{1,10}', 'g');
      const lines = label.match(regex);
      if (lines) {
        el.setAttribute('label', lines.join('\n'));
      }
    }
  },
});
