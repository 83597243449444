var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/fields" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "pen" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(_vm.$t("global.menu.entities.fields")),
                  },
                },
                [_vm._v("Fields")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/space" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "pen" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(_vm.$t("global.menu.entities.space")),
                  },
                },
                [_vm._v("Space")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/status-type" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "pen" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("global.menu.entities.statusType")
                    ),
                  },
                },
                [_vm._v("Status Type")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/organisation-type" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "pen" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("global.menu.entities.organisationType")
                    ),
                  },
                },
                [_vm._v("Organisation Type")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/area" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "object-group" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(_vm.$t("global.menu.entities.area")),
                  },
                },
                [_vm._v("Area")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/workflow" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "spinner" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("global.menu.entities.workflow")
                    ),
                  },
                },
                [_vm._v("Workflow")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/gate" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "door-open" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(_vm.$t("global.menu.entities.gate")),
                  },
                },
                [_vm._v("Gate")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/operator" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "users" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("global.menu.entities.operator")
                    ),
                  },
                },
                [_vm._v("Operator")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/scangun" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "users" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(_vm.$t("global.menu.entities.scangun")),
                  },
                },
                [_vm._v("Scangun")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      (_vm.hasAnyAuthority("ROLE_CONFIGURATION") ||
        _vm.hasAnyAuthority("ROLE_ADMIN")) &&
      _vm.authenticated
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/user-management", "active-class": "active" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "users" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("global.menu.admin.userManagement")
                    ),
                  },
                },
                [_vm._v("User management")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }