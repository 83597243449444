import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

const Area = () => import('@/entities/area/area.vue');
const AreaUpdate = () => import('@/entities/area/area-update.vue');
const AreaDetails = () => import('@/entities/area/area-details.vue');

const Gate = () => import('@/entities/gate/gate.vue');
const GateUpdate = () => import('@/entities/gate/gate-update.vue');
const GateDetails = () => import('@/entities/gate/gate-details.vue');

const Entry = () => import('@/entities/entry/entry.vue');
const EntryDetails = () => import('@/entities/entry/entry-details.vue');

const Fields = () => import('@/entities/fields/fields.vue');
const FieldsUpdate = () => import('@/entities/fields/fields-update.vue');
const FieldsDetails = () => import('@/entities/fields/fields-details.vue');

const Workflow = () => import('@/entities/workflow/workflow.vue');
const WorkflowUpdate = () => import('@/entities/workflow/workflow-update.vue');
const WorkflowDetails = () => import('@/entities/workflow/workflow-details.vue');

const Audit = () => import('@/entities/audit/audit.vue');
const AuditUpdate = () => import('@/entities/audit/audit-update.vue');
const AuditDetails = () => import('@/entities/audit/audit-details.vue');

const Person = () => import('@/entities/person/person.vue');
const PersonUpdate = () => import('@/entities/person/person-update.vue');
const PersonDetails = () => import('@/entities/person/person-details.vue');

const Vehicule = () => import('@/entities/vehicule/vehicule.vue');
const VehiculeUpdate = () => import('@/entities/vehicule/vehicule-update.vue');
const VehiculeDetails = () => import('@/entities/vehicule/vehicule-details.vue');

const OrganisationType = () => import('@/entities/organisation-type/organisation-type.vue');
const OrganisationTypeUpdate = () => import('@/entities/organisation-type/organisation-type-update.vue');
const OrganisationTypeDetails = () => import('@/entities/organisation-type/organisation-type-details.vue');

const Organisation = () => import('@/entities/organisation/organisation.vue');
const OrganisationUpdate = () => import('@/entities/organisation/organisation-update.vue');
const OrganisationDetails = () => import('@/entities/organisation/organisation-details.vue');

const StatusValue = () => import('@/entities/status-value/status-value.vue');
const StatusValueUpdate = () => import('@/entities/status-value/status-value-update.vue');
const StatusValueDetails = () => import('@/entities/status-value/status-value-details.vue');

const StatusType = () => import('@/entities/status-type/status-type.vue');
const StatusTypeUpdate = () => import('@/entities/status-type/status-type-update.vue');
const StatusTypeDetails = () => import('@/entities/status-type/status-type-details.vue');

// prettier-ignore
const ScanEntityApp = () => import('@/entities/scan-entity-app/scan-entity-app.vue');
// prettier-ignore
const ScanEntityAppUpdate = () => import('@/entities/scan-entity-app/scan-entity-app-update.vue');
// prettier-ignore
const ScanEntityAppDetails = () => import('@/entities/scan-entity-app/scan-entity-app-details.vue');
// prettier-ignore
const Operator = () => import('@/entities/operator/operator.vue');
// prettier-ignore
const OperatorUpdate = () => import('@/entities/operator/operator-update.vue');
// prettier-ignore
const OperatorDetails = () => import('@/entities/operator/operator-details.vue');
// prettier-ignore
const Scangun = () => import('@/entities/scangun/scangun.vue');
// prettier-ignore
const ScangunUpdate = () => import('@/entities/scangun/scangun-update.vue');
// prettier-ignore
const ScangunDetails = () => import('@/entities/scangun/scangun-details.vue');

const Space = () => import('@/entities/space/space.vue');
const SpaceUpdate = () => import('@/entities/space/space-update.vue');
const SpaceDetails = () => import('@/entities/space/space-details.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'area',
      name: 'Area',
      component: Area,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'area/new',
      name: 'AreaCreate',
      component: AreaUpdate,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'area/:areaId/edit',
      name: 'AreaEdit',
      component: AreaUpdate,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'area/:areaId/view',
      name: 'AreaView',
      component: AreaDetails,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'space',
      name: 'Space',
      component: Space,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'space/new',
      name: 'SpaceCreate',
      component: SpaceUpdate,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'space/:spaceId/edit',
      name: 'SpaceEdit',
      component: SpaceUpdate,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'space/:spaceId/edit',
      name: 'Space',
      component: SpaceDetails,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'gate',
      name: 'Gate',
      component: Gate,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'gate/new',
      name: 'GateCreate',
      component: GateUpdate,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'gate/:gateId/edit',
      name: 'GateEdit',
      component: GateUpdate,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'gate/:gateId/view',
      name: 'GateView',
      component: GateDetails,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'entry',
      name: 'Entry',
      component: Entry,
      meta: { authorities: [Authority.MANAGER] },
    },
    {
      path: 'entry/:entryId/view',
      name: 'EntryView',
      component: EntryDetails,
      meta: { authorities: [Authority.MANAGER] },
    },
    {
      path: 'fields',
      name: 'Fields',
      component: Fields,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'fields/new',
      name: 'FieldsCreate',
      component: FieldsUpdate,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'fields/:fieldsId/edit',
      name: 'FieldsEdit',
      component: FieldsUpdate,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'fields/:fieldsId/view',
      name: 'FieldsView',
      component: FieldsDetails,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'workflow',
      name: 'Workflow',
      component: Workflow,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'workflow/new',
      name: 'WorkflowCreate',
      component: WorkflowUpdate,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'workflow/:workflowId/edit',
      name: 'WorkflowEdit',
      component: WorkflowUpdate,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'workflow/:workflowId/view',
      name: 'WorkflowView',
      component: WorkflowDetails,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'audit',
      name: 'Audit',
      component: Audit,
      meta: { authorities: [Authority.MANAGER] },
    },
    {
      path: 'audit/new',
      name: 'AuditCreate',
      component: AuditUpdate,
      meta: { authorities: [Authority.MANAGER] },
    },
    {
      path: 'audit/:auditId/edit',
      name: 'AuditEdit',
      component: AuditUpdate,
      meta: { authorities: [Authority.MANAGER] },
    },
    {
      path: 'audit/:auditId/view',
      name: 'AuditView',
      component: AuditDetails,
      meta: { authorities: [Authority.MANAGER] },
    },
    {
      path: 'person',
      name: 'Person',
      component: Person,
      meta: { authorities: [Authority.ORGANISATION, Authority.MANAGER] },
    },
    {
      path: 'person/new',
      name: 'PersonCreate',
      component: PersonUpdate,
      meta: { authorities: [Authority.ORGANISATION, Authority.MANAGER] },
    },
    {
      path: 'person/new/:organisation',
      name: 'PersonCreateOrga',
      component: PersonUpdate,
      meta: {},
    },
    {
      path: 'person/:personId/edit',
      name: 'PersonEdit',
      component: PersonUpdate,
      meta: { authorities: [Authority.ORGANISATION, Authority.MANAGER] },
    },
    {
      path: 'person/:personId/view',
      name: 'PersonView',
      component: PersonDetails,
      meta: { authorities: [Authority.ORGANISATION, Authority.MANAGER] },
    },
    {
      path: 'vehicule',
      name: 'Vehicule',
      component: Vehicule,
      meta: { authorities: [Authority.ORGANISATION, Authority.MANAGER] },
    },
    {
      path: 'vehicule/new',
      name: 'VehiculeCreate',
      component: VehiculeUpdate,
      meta: { authorities: [Authority.ORGANISATION, Authority.MANAGER] },
    },
    {
      path: 'vehicule/new/:organisation',
      name: 'VehiculeCreateOrga',
      component: VehiculeUpdate,
      meta: {},
    },
    {
      path: 'vehicule/:vehiculeId/edit',
      name: 'VehiculeEdit',
      component: VehiculeUpdate,
      meta: { authorities: [Authority.ORGANISATION, Authority.MANAGER] },
    },
    {
      path: 'vehicule/:vehiculeId/view',
      name: 'VehiculeView',
      component: VehiculeDetails,
      meta: { authorities: [Authority.ORGANISATION, Authority.MANAGER] },
    },
    {
      path: 'organisation-type',
      name: 'OrganisationType',
      component: OrganisationType,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'organisation-type/new',
      name: 'OrganisationTypeCreate',
      component: OrganisationTypeUpdate,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'organisation-type/:organisationTypeId/edit',
      name: 'OrganisationTypeEdit',
      component: OrganisationTypeUpdate,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'organisation-type/:organisationTypeId/view',
      name: 'OrganisationTypeView',
      component: OrganisationTypeDetails,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'organisation',
      name: 'Organisation',
      component: Organisation,
      meta: { authorities: [Authority.MANAGER] },
    },
    {
      path: 'organisation/new',
      name: 'OrganisationCreate',
      component: OrganisationUpdate,
      meta: { authorities: [Authority.MANAGER] },
    },
    {
      path: 'organisation/:organisationId/edit',
      name: 'OrganisationEdit',
      component: OrganisationUpdate,
      meta: { authorities: [Authority.MANAGER] },
    },
    {
      path: 'organisation/:organisationId/view',
      name: 'OrganisationView',
      component: OrganisationDetails,
      meta: { authorities: [Authority.MANAGER] },
    },
    {
      path: 'status-value',
      name: 'StatusValue',
      component: StatusValue,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'status-value/new',
      name: 'StatusValueCreate',
      component: StatusValueUpdate,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'status-value/:statusValueId/edit',
      name: 'StatusValueEdit',
      component: StatusValueUpdate,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'status-value/:statusValueId/view',
      name: 'StatusValueView',
      component: StatusValueDetails,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'status-type',
      name: 'StatusType',
      component: StatusType,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'status-type/new',
      name: 'StatusTypeCreate',
      component: StatusTypeUpdate,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'status-type/:statusTypeId/edit',
      name: 'StatusTypeEdit',
      component: StatusTypeUpdate,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    {
      path: 'status-type/:statusTypeId/view',
      name: 'StatusTypeView',
      component: StatusTypeDetails,
      meta: { authorities: [Authority.CONFIGURATION] },
    },
    /*
     ##### Vestiges de la version mobile du site pour scanner les accréditations #####
    {
      path: 'scan-entity-app',
      name: 'ScanEntityApp',
      component: ScanEntityApp,
      meta: { authorities: [Authority.OPERATOR, Authority.ADMIN] },
    },
    {
      path: 'scan-entity-app/new',
      name: 'ScanEntityAppCreate',
      component: ScanEntityAppUpdate,
      meta: { authorities: [Authority.OPERATOR, Authority.ADMIN] },
    },
    {
      path: 'scan-entity-app/:scanEntityAppId/edit',
      name: 'ScanEntityAppEdit',
      component: ScanEntityAppUpdate,
      meta: { authorities: [Authority.OPERATOR, Authority.ADMIN] },
    },
    {
      path: 'scan-entity-app/:scanEntityAppId/view',
      name: 'ScanEntityAppView',
      component: ScanEntityAppDetails,
      meta: { authorities: [Authority.OPERATOR, Authority.ADMIN] },
    },*/
    {
      path: 'operator',
      name: 'Operator',
      component: Operator,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'operator/new',
      name: 'OperatorCreate',
      component: OperatorUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'operator/:operatorId/edit',
      name: 'OperatorEdit',
      component: OperatorUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'operator/:operatorId/view',
      name: 'OperatorView',
      component: OperatorDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'scangun',
      name: 'Scangun',
      component: Scangun,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'scangun/new',
      name: 'ScangunCreate',
      component: ScangunUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'scangun/:scangunId/edit',
      name: 'ScangunEdit',
      component: ScangunUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'scangun/:scangunId/view',
      name: 'ScangunView',
      component: ScangunDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
