var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isAllowedToPerson
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/person" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "users" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(_vm.$t("global.menu.entities.person")),
                  },
                },
                [_vm._v("Person")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isAllowedToVehicule
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/vehicule" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "car" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("global.menu.entities.vehicule")
                    ),
                  },
                },
                [_vm._v("Vehicule")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }