var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "footer", attrs: { id: "footer" } }, [
    _c("div", { staticClass: "row justify-content-center" }, [
      _c(
        "span",
        [_c("font-awesome-icon", { attrs: { icon: "copyright" } })],
        1
      ),
      _vm._v(" "),
      _c("span", { domProps: { textContent: _vm._s(_vm.$t("footer")) } }, [
        _vm._v("This is your footer"),
      ]),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "right", attrs: { href: "mailto:info@wiusc.com" } },
        [_vm._v("info@wiusc.com")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }